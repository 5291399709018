<template>
    <div class="">
        <audio  
          ref="initAudio"
          autoplay
          :src="propValue">
        </audio>
        <div class="audioControls">
          <i class="iconfont icon-yinle"></i>
        </div>
    </div>

</template>

<script>
export default {
    props: {
        propValue: {
            type: String,
            require: true,
        },
    },
}
</script>

<style lang="scss" scoped>
</style>